import React from 'react';
import { Link } from "react-router-dom";

import bath_1 from "../../Assets/images/bath_1.png";

export const Intro = (props) => {
  
  const { 
    desc,
    className
  } = props;

  const renderButton = () =>{    
    const groupColor = (localStorage.getItem("ABTGroup") === 'B' || window.ABLabel === 'B') ? 'ctaB' : 'cta';
      
    return <Link to="/get-started" className={`blue-btn margin-auto ${groupColor}`}>Get Started</Link>

  }

  return (
    <section className={`works-intro ${className || ''}`}>
      <div className="container">
        <div className="cnt">
          <div className="text">
            <div className="text-cnt text-lft">
              <h3>Feeling inspired?</h3>
              <p>
                {desc}
              </p>
              { renderButton() }
            </div>
          </div>
          <div className="image">
            <div className="image-cnt">
              <img src={bath_1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}