import React from "react";
import { Link } from "react-router-dom";

import "./HowItWorksHome.scss";

export const HowItWorksHome = ({ stepItems }) => {
  const groupColor = (localStorage.getItem("ABTGroup") === 'B' || window.ABLabel === 'B') ? 'ctaB' : 'cta';
      
  return (
    <section className="windowsHowItWorksHome">
      <h2>HOW IT WORKS</h2>
      <div className="stepSection">
        {stepItems.map((item) => (
          <div key={item.id} className="stepSection__item">
            <img src={item.img} alt={item.alt} />
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>

      <Link to="/get-started" className={`getStartBtn ${groupColor}`}>
        <button>Let’s Get Started!</button>
      </Link>
    </section>
  );
};
