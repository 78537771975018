import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import { OurAwards } from "../../Containers/Home";
import StationsUs from "../../Common/StationsUS";
import Stations from "../../Common/Stations";
import { Banner } from "../../Common/promoBanner";
import { Helmet } from "react-helmet";
import VideoPreview from '../../Containers/Home/VideoPreview';

import { WhatTheySayHomeWindows } from "../../Containers/Home/windows/WhatTheySayHomeWindows";
import { EnjoyNewWindow } from "../../Containers/Home/windows/EnjoyNewWindow";
import { WindowsOurLatestWork } from "../../Containers/Home/windows/WindowsOurLatestWork";
import { WindowsRedyStarted } from "../../Containers/Home/windows/WindowsRedyStarted";
import { WindowsHomeTop } from "../../Containers/Home/windows/WindowsHomeTop";
import { HowItWorksHome } from "../../Containers/Home/windows/HowItWorksHome";

import LatestWorkOne from "../../Assets/images/roof/ourWorkGalery/sdf.jpg";
import LatestWorkTwo from "../../Assets/images/roof/ourWorkGalery/trt.jpg";
import LatestWorkThree from "../../Assets/images/roof/ourWorkGalery/sdss.jpg";
import LatestWorkFour from "../../Assets/images/roof/ourWorkGalery/cvf.jpg";
import LatestWorkFive from "../../Assets/images/roof/ourWorkGalery/olo.jpg";

import TestimonialsReviewerTwo from "./../../Assets/testimonials_images/pic-10.png";
import TestimonialsReviewerThree from "./../../Assets/testimonials_images/pic-08.png";

import TestimonialsReviewerFive from "./../../Assets/testimonials_images/pic-14.png";
import TestimonialsReviewerSix from "./../../Assets/testimonials_images/pic-04.png";
import TestimonialsReviewerSeven from "./../../Assets/testimonials_images/pic-16.png";


import Insulatiom from "../../Assets/images/roof/home/insulation.png";
import Siding from "../../Assets/images/roof/home/Siding.png";
import Skylights from "../../Assets/images/roof/home/skylights.png";
import SoffitFascia from "../../Assets/images/roof/home/soffit-fascia.png";
import RoofLeakRepairs from "../../Assets/images/roof/home/roof-leak-repairs.png";
import NewRoofing from "../../Assets/images/roof/home/new-roofing.png";

// import VinylWindows from "../../Assets/images/windows/how-it-works/vinyl-windows.png";
// import EntryDoors from "../../Assets/images/windows/how-it-works/entry-doors.png";
// import PatioDoors from "../../Assets/images/windows/how-it-works/patio-doors.png";
// import GarageDoors from "../../Assets/images/windows/how-it-works/garage.png";
import HighestBackground from "../../Assets/images/windows/home/highest-rare-background.png";


import TabletStep1 from "../../Assets/images/windows/home/howItWork-step1.png";
import LaptopStep2 from "../../Assets/images/windows/home/howItWork-step2.png";
import Step3 from "../../Assets/images/roof/home/install3.png";

import WindowIcon1 from "../../Assets/images/windows/home/window-icon-1.png";
import WindowIcon2 from "../../Assets/images/windows/home/window-icon-2.png";
import WindowIcon3 from "../../Assets/images/windows/home/window-icon-3.png";
import WindowIcon4 from "../../Assets/images/windows/home/window-icon-4.png";
import WindowIcon5 from "../../Assets/images/windows/home/window-icon-5.png";

// import Window1 from "../../Assets/images/windows/home/window1.png";
// import Window2 from "../../Assets/images/windows/home/window2.png";
// import Window3 from "../../Assets/images/windows/home/window3.png";
// import Window4 from "../../Assets/images/windows/home/window4.png";
// import Window5 from "../../Assets/images/windows/home/window5.png";

import Shingle from "../../Assets/images/roof/home/shingle.png";
import Cedar from "../../Assets/images/roof/home/cedar.png";
import Slate from "../../Assets/images/roof/home/slate.png";
import Metal from "../../Assets/images/roof/home/metal.png";
import Clay from "../../Assets/images/roof/home/clay.png";
import Flat from "../../Assets/images/roof/home/flat.png";

import ShingleMobile from "../../Assets/images/roof/home/Shingle-mobile.png";
import CedarMobile from "../../Assets/images/roof/home/Cedar-mobile.png";
import SlateMobile from "../../Assets/images/roof/home/Slate-mobile.png";
import MetalMobile from "../../Assets/images/roof/home/Metal-mobile.png";
import ClayMobile from "../../Assets/images/roof/home/Clay-mobile.png";
import FlatMobile from "../../Assets/images/roof/home/Flat-mobile.png";


const doorMenu = [
  {
    id: 0,
    img: NewRoofing,
    alt: "NewRoofing",
    path: "new-roofing",
    title: "New Roofing",
  },
  {
    id: 1,
    img: RoofLeakRepairs,
    alt: "RoofLeakRepairs",
    path: "/roof-leak-repairs",
    title: "Roof Leak Repairs",
  },
  {
    id: 2,
    img: Insulatiom,
    alt: "Insulatiom",
    path: "insulation",
    title: "Insulation",
  },
  {
    id: 3,
    img: SoffitFascia,
    alt: "SoffitFascia",
    path: "soffit-fascia-eavestrought",
    title: "Soffit, Fascia & Eavestrough",
    // left: "-48px",
    // top: "-8px",
  },
  {
    id: 4,
    img: Skylights,
    alt: "Skylights",
    path: "skylights",
    title: "Skylights",
    // left: "-48px",
    // top: "-8px",
  },
  // {
  //   id: 5,
  //   img: Siding,
  //   alt: "Siding",
  //   path: "siding",
  //   title: "Siding",
  //   // left: "-48px",
  //   // top: "-8px",
  // },
];
const className = "roof";
const stepItems = [
  {
    id: 0,
    img: TabletStep1,
    alt: "Tablet",
    title: `Get a FREE Quote`,
    description: `Book your free consultation with our roofing experts. We will provide a detailed quote and scope for the project.`,
  },
  {
    id: 1,
    img: LaptopStep2,
    alt: "Laptop",
    title: `Plan Your Roof`,
    description: `We will provide you with all of the options available to you. Choose the materials, style and colour of your roof.
    `,
  },
  {
    id: 2,
    img: Step3,
    alt: "Window",
    title: `The Work Begins!`,
    description: `Our team will begin the work according to the agreed upon scope, contract and timeline. Your new roof is just around the corner!`,
  },
];

const windowIcons = [
  { id: 0, img: WindowIcon1, alt: "Window1" },
  {
    id: 1,
    img: WindowIcon2,
    alt: "Window2",
  },
  { id: 2, img: WindowIcon3, alt: "Window3" },
  {
    id: 3,
    img: WindowIcon4,
    alt: "Window4",
  },
  {
    id: 4,
    img: WindowIcon5,
    alt: "Window5",
  },
];

const roofImages = {
  0: [Shingle],
  1: [Cedar],
  2: [Slate],
  3: [Metal],
  4: [Clay],
  5: [Flat],
};
const roofImagesMobile = {
  0: [ShingleMobile],
  1: [CedarMobile],
  2: [SlateMobile],
  3: [MetalMobile],
  4: [ClayMobile],
  5: [FlatMobile],
};

const latestWorksItems = [
  LatestWorkOne,
  LatestWorkTwo,
  LatestWorkThree,
  LatestWorkFour,
  LatestWorkFive,
];
const checkList = [
  "Complete projects on time and within budget",
  "No added fees or hidden costs",
  "Decades of customer satisfaction",
  "Commitment to quality in all that we do",
  "Provide guidance, information and transparency",
];

const testimonialsItems = [
  {
    id: 0,
    text: `AGM did our bathroom for a massage business just in the past
    summer. Great sales and design experience. Your company's
    generosity culture showed at all stages of the project. Had
    a few hiccups with selected products not being available
    from the suppliers, but with patience we got through it all.
    You guys have very courteous and professional employees. Had
    great experiences with some of them. Can't recall their
    specific names.`,
    name: "K.T. Addo",
    photo: TestimonialsReviewerTwo,
  },
  {
    id: 1,
    text: `We hired AGM to renovate our bathroom which we are very
    happy with. From initial meeting with Andrea to working with
    Kate at the design studio on structure and design to making
    the vision come to life with Johnny the project manager.
    Johnny kept us updated throughout the process and was very
    helpful and informative.`,
    name: "Carm Ragno",
    photo: TestimonialsReviewerFive,
  },
  {
    id: 2,
    text: `AGM has finished our bathroom really well. We have a
    beautiful living area. Love the signature wall unit that AGM
    has installed. Workers are very professional. We believe AGM
    has given us a great value for the price we paid. We thank
    everyone at AGM. For the great work!`,
    name: "Josie Vierra",
    photo: TestimonialsReviewerThree,
  },
  {
    id: 3,
    text: `It was a great experience to watch our bathroom work done by
    AGM workers. Very experienced and well organized team. We
    have a nice entertainment area, a nice kitchen and now a
    very newly built beautiful bathroom. Overall we are very
    happy with our bathroom. The team at AGM has our
    recommendations to all families looking to finish their
    bathroom!`,
    name: "Kay Walter",
    photo: TestimonialsReviewerSix,
  },
  {
    id: 4,
    text: `We had our bathroom finished by AGM, and I'm completely
    happy with the result. Of the 3 estimates we had done, they
    were the cheapest AND gave us the most finished space. They
    finished the project on time, and their staff were
    knowledgeable and friendly.`,
    name: "Jan Genoch",
    photo: TestimonialsReviewerSeven,
  },
];

const HomePage = (props) => {

  let region = "";
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = "";
  }
  let city = "";
  if (sessionStorage.getItem("city")) {
    city = sessionStorage.getItem("city");
  } else {
    city = "";
  }

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_META_DESCRIPTION} />
        <meta name="keywords" content={process.env.REACT_APP_META_KEYWORDS} />
        <meta name="robots" content="index"/>
      </Helmet>

      <WindowsHomeTop className={className} doorMenu={doorMenu} />
      <Banner/>

      {props?.location?.countryCode === "US" 
        ? <StationsUs /> 
        : <Stations />
      }

      <VideoPreview countryCode={props?.location?.countryCode}/>
      <EnjoyNewWindow
        className={className}
        windowIcons={windowIcons}
        windowImages={roofImages}
        mobileImgs={roofImagesMobile}
      />
      <HowItWorksHome stepItems={stepItems} />
      <WhatTheySayHomeWindows items={testimonialsItems} />
      <OurAwards blueHeader={true} background={HighestBackground} city={city} />
      <WindowsOurLatestWork
        title="Explore Our Roofing Installation Gallery"
        subtitle="View the finished images from our latest local roofing projects!"
        items={latestWorksItems}
      />
      <WindowsRedyStarted checkList={checkList} />
    </>
  );
};

export { HomePage };
