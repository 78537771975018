import React from "react";

import { Link } from "react-router-dom";

export const CallToAction = (props) => {
  const { text, className, paragrapht } = props;
  const groupColor = (localStorage.getItem("ABTGroup") === 'B' || window.ABLabel === 'B') ? 'ctaB' : 'cta';
      
  let region = "";
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = "";
  }

  const renderButton = () => {
    const isKitchenApp = process.env.REACT_APP_NAME === "kitchens";
    // if(region==='vancouver' || region ==='calgary'){
    if (isKitchenApp || true) {
      return (
        <Link to="/get-started" className={`blue-btn margin-auto ${groupColor}`}>
          Get Started
        </Link>
      );
    } else {
      return (
        <a href="/design" className="blue-btn margin-auto-mobile">
          Get Started
        </a>
      );
    }
  };

  return (
    <section className={`about-section-3 ${className || ""}`}>
      <div className="container">
        <div className="cnt">
          <h3>
            <span>Get Your Quote</span>
          </h3>

          {paragrapht ? (
            <span>
              {paragrapht.map((paragraph, index) => (
                <p style={{ fontSize: '26px'}} key={index}>{paragraph}</p>
              ))}
            </span>
          ) : (
            <span> {text}</span>
          )}

          {renderButton()}
        </div>
      </div>
    </section>
  );
};
