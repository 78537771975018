import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import VideoModal from "../../../Common/videoModal";
import { DoorsNav } from "../../DoorsNav/DoorsNav";

// import Ivan from "../../../Assets/images/windows/home/ivan-top-banner.png";
// import Mike from "../../../Assets/images/windows/home/mike-top-banner.png";
// import ArrowRight from "../../../Assets/images/windows/home/arrow-right.png";
import promo2 from "../../../Assets/images/promo2.gif";

import "./WindowsHomeTop.scss";

export const WindowsHomeTop = ({ doorMenu, className }) => {
  const groupColor = (localStorage.getItem("ABTGroup") === 'B' || window.ABLabel === 'B') ? 'ctaB' : 'cta';
 
  
  // this.videos = {
  //   laptopVideo: this.state.countryCode === 'CA' ? '0_Rr23cnD0Q' : 'TeXeepjTWKA',
  //   HGTV: '5kVBooRtR4Q',
  // };

    const [isOpen, setIsOpenModal] = useState(null);
    const [isShowPromo, setShowPromo] = useState(false);

    const promoRef = useRef(null);
    
  
    const handleScroll = (e) => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

      if (winScroll >= 70) {
        if (!isShowPromo && promoRef.current) {
          promoRef.current.classList.add('visible');
          setShowPromo(true);
        }
      } else {
        if (isShowPromo && promoRef.current) {
          promoRef.current.classList.remove('visible');
          setShowPromo(false);
        }
      }

    };
    document.addEventListener("scroll", handleScroll);
   
  return (
    <>
    <section className="windowsHomeTop">
      <DoorsNav className={className} doorMenu={doorMenu} />
      <div className={className ? `topBanner ${className}` : "topBanner"}>

        {/* <Link to="/get-started">
          <img className="promo2" ref={promoRef} src={promo2} />   
        </Link> */}
        
        {/* <div className="promoStamp">
          <button onClick={(e)=> {
            e.currentTarget.parentElement.style.display = 'none';
           }} className="closeBtn">
            <CloseIcon/>
          </button>
          <p className="topPar">
            <span>
              Save %20 on Roofing installation
            </span>
          </p>

          <p className="middleOffer desk">
           <strong>No</strong> Money Down <br/>
           <strong>No</strong> Interest <br/>
           <strong>No</strong> Payments for 1 Year*
          </p>

          <p className="middleOffer mobile">
            <strong>No</strong> Money Down, <strong>No</strong> Interest, <strong>No</strong> Payments for 1 Year*
          </p>

          <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image extra desk">
           <strong>Take an extra $2,000 OFF your project</strong>
          </p>

          <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image mobile">
           <strong>Take an extra $2,000 OFF your project</strong>
          </p>

            <Link sx={{ color: '#fff', fontWeight: 700 }} to="/get-started?offer=1" className="blue-dark-windows-btn">
            <div style={{ color: '#fff', fontWeight: 700 }} className="button">
              See Offer
            </div>
            </Link>
      

          <span className="endPart">Offer ends 02-15-25</span>
        </div> */}

        {/* <div className="ivanSection">
          <img src={Ivan} alt="Ivan" />
          <div className="ivanSection__description">
            <h3>Ivan Atanasov</h3>
            <h4>CEO AGM Renovations</h4>
          </div>
        </div> */}
        {/* style={{ background: 'linear-gradient(rgb(8 199 253), rgb(0 157 201))' }} */}
        <div className="textSection">
          <h2>Roofing Replacements</h2>
          <h3>Done Right!</h3>
          <Link to="/get-started" className={`blue-dark-windows-btn ${groupColor}`}>
            <button className={`blue-dark-windows-btn ${groupColor}`}>GET FREE QUOTE</button>
          </Link>
        </div>
        {/* <div className="mikeSection">
          <img src={Mike} alt="Mike" />
          <div  onClick={()=>{setIsOpenModal(true);}} className="mikeSectionDescription">
            <h3>Mike Holmes</h3>
            <span>
              <h4>HGTV Star & Contractor</h4>
            </span>
          </div>
        </div> */}
      </div>
    </section>

    { isOpen && 
      <VideoModal
        isOpen={isOpen}
        videoId={'0_Rr23cnD0Q'}
        handleClose={ ()=>{ setIsOpenModal(false); } }
      />
    }

    </>
  );
};
