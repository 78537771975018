import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useBreakPoint } from "../../../hooks/useResponsive";

import "./EnjoyNewWindow.scss";

const buttons = ["Shingle", "Cedar", "Slate", "Metal", "Clay", "Flat"];

export const EnjoyNewWindow = ({
  windowIcons,
  windowImages,
  mobileImgs,
  className,
}) => {
  const [activeRoof, setActiveRoof] = useState(0);
  const groupColor = (localStorage.getItem("ABTGroup") === 'B' || window.ABLabel === 'B') ? 'ctaB' : 'cta';
  const breakpoint = useBreakPoint();
  return breakpoint !== 980 ? (
    <section
      className={className ? `enjoyNewWindow ${className}` : "enjoyNewWindow"}
      style={{ backgroundImage: `url(${windowImages[activeRoof]})` }}
    >
      {/* {breakpoint !== 980 ? ( */}
      <>
        <div className="enjoyTxt">
          <h2>Your Full-Service Roofing Company</h2>
          <h3>Enjoy your newly-installed roof.</h3>
          <div className="enjoyTxt__windows">
            {buttons.map((item, index) => (
              <button
                key={index}
                className={index === activeRoof ? "roofBtn active" : "roofBtn"}
                onClick={(e) => {
                  setActiveRoof(index);
                }}
              >
                {item}
              </button>
            ))}
          </div>
          <Link to="/get-started" >
            <button className={`blue-dark-windows-btn ${groupColor}`}>Get Started</button>
          </Link>
        </div>
        {className === "roof"
          ? null
          : windowImages[activeRoof].map((item, index) => (
              <img
                key={index}
                className="wndType"
                src={item}
                alt={"windowImage"}
              />
            ))}
      </>
    </section>
  ) : (
    <section
      className={className ? `enjoyNewWindow ${className}` : "enjoyNewWindow"}
      style={{ backgroundImage: `url(${mobileImgs[activeRoof]})` }}
    >
      <>
        <div className="enjoyTxt">
          <h2>Discover Your New Windows!</h2>
          <div className="enjoyTxt__btns">
            {buttons.map((item, index) => (
              <button
                key={index}
                className={index === activeRoof ? "roofBtn active" : "roofBtn"}
                onClick={(e) => {
                  setActiveRoof(index);
                }}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
      </>
    </section>
  );
};
